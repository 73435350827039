define("travis/components/request-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var eventToIcon = {
    push: 'push',
    pull_request: 'pullrequest',
    cron: 'cronjobs',
    api: 'api',
    default: 'nobuilds'
  };
  var eventToTitle = {
    push: 'Triggered by a push',
    pull_request: 'Triggered from a pull request',
    cron: 'Triggered by a cron job',
    api: 'Triggered via the API',
    default: 'Triggered via unknown means'
  };

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['event', 'state'],
    attributeBindings: ['title'],
    icon: Ember.computed('event', function () {
      var event = this.event;
      var iconName = eventToIcon[event] || eventToIcon.default;
      return "icon-".concat(iconName);
    }),
    title: Ember.computed('event', function () {
      var event = this.event;
      return eventToTitle[event] || eventToTitle.default;
    })
  });

  _exports.default = _default;
});